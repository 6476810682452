* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

input::placeholder {
  opacity: 0.8;
}

a {
  color: inherit;
  text-decoration: inherit;
}
